import React from 'react'

const HeartFilled = (props) => (
  <svg width="13px" height="11px" viewBox="0 0 13 11" {...props}>
    <title>icon / heart</title>
    <defs>
      <linearGradient
        x1="0%"
        y1="16.0811526%"
        x2="100%"
        y2="83.9188474%"
        id="linearGradient-1"
      >
        <stop stopColor="#E76161" offset="0%"></stop>
        <stop stopColor="#6750AE" offset="52.6440571%"></stop>
        <stop stopColor="#3DD7D5" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="overview-/-xperience-tile"
        transform="translate(-741.000000, -18.000000)"
      >
        <g id="Group" transform="translate(741.000000, 15.000000)">
          <g id="icon-/-heart" transform="translate(0.000000, 2.000000)">
            <rect id="Rectangle" x="0" y="0" width="13" height="13"></rect>
            <path
              d="M6.5,3.78654608 L7.50533256,2.78121352 C8.61361726,1.67292883 10.4105018,1.67292883 11.5187865,2.78121352 C12.6270712,3.88949822 12.6270712,5.68638274 11.5187865,6.79466744 L6.72443449,11.3722587 C6.59883881,11.4921759 6.40116119,11.4921759 6.27556551,11.3722587 L1.48121352,6.79466744 L1.48121352,6.79466744 C0.372928826,5.68638274 0.372928826,3.88949822 1.48121352,2.78121352 C2.58949822,1.67292883 4.38638274,1.67292883 5.49466744,2.78121352 L6.5,3.78654608 Z"
              id="Path"
              fill="url(#linearGradient-1)"
              fillRule="evenodd"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default HeartFilled
