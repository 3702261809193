import React from "react";
import client from "./src/graphql/client";
import { ApolloProvider } from "@apollo/client";
import Modal from "react-modal";
import "./src/styles/base.scss";
import "typeface-open-sans"; // eslint-disable-next-line react/prop-types

export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>;

if (typeof window !== "undefined") {
  Modal.setAppElement("#___gatsby");
}
