import React from 'react'

const SvgBackArrow = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
    <g fill="none" fillRule="evenodd" style={{ border: '1px solid white' }}>
      <circle fill="#38383F" cx={25} cy={25} r={25} />
      <path d="M40 10v30H10V10z" />
      <path
        d="M38.333 25c0 .741-.58 1.342-1.297 1.342h-14.27c-.716 0-1.297-.6-1.297-1.342 0-.741.58-1.342 1.297-1.342h14.27c.717 0 1.297.6 1.297 1.342zm-22.997 0l8.255 8.542a1.375 1.375 0 0 1 0 1.898 1.267 1.267 0 0 1-1.834 0L11.667 25l10.09-10.44a1.267 1.267 0 0 1 1.834 0 1.375 1.375 0 0 1 0 1.898L15.336 25z"
        fill="#F7F7F7"
      />
    </g>
  </svg>
)

export default SvgBackArrow
