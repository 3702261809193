import React, { useState } from "react";
import SearchContext from "State/searchContext";

export default function SearchProvider({ children }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [showLiked, setShowLiked] = useState(false);

  return (
    <SearchContext.Provider value={{ searchQuery, setSearchQuery, showLiked, setShowLiked }}>
      {children}
    </SearchContext.Provider>
  );
}
