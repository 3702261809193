import React from 'react'

const Heart = (props) => (
  <svg width="28px" height="23px" viewBox="0 0 28 23" {...props}>
    <title>Liked Experiences</title>
    <defs>
      <linearGradient
        x1="0%"
        y1="16.0811526%"
        x2="100%"
        y2="83.9188474%"
        id="linearGradient-1"
      >
        <stop stopColor="#E76161" offset="0%"></stop>
        <stop stopColor="#6750AE" offset="50.1058807%"></stop>
        <stop stopColor="#3DD7D5" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="nav-/-top-nav" transform="translate(-1237.000000, -19.000000)">
        <g id="nav">
          <g id="icon-/-heart" transform="translate(1236.000000, 15.000000)">
            <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
            <path
              d="M11.972895,7.12529183 C10.8893671,6.04176394 9.46923026,5.5 8.04909341,5.5 C6.62895657,5.5 5.20881972,6.04176394 4.12529183,7.12529183 C3.04176394,8.20881972 2.5,9.62895657 2.5,11.0490934 C2.5,12.4658024 3.03915172,13.8825114 4.11745517,14.965043 L15,25.3555695 L25.8825448,14.965043 C26.9608483,13.8825114 27.5,12.4658024 27.5,11.0490934 C27.5,9.62895657 26.9582361,8.20881972 25.8747082,7.12529183 C24.7911803,6.04176394 23.3710434,5.5 21.9509066,5.5 C20.5307697,5.5 19.1106329,6.04176394 18.027105,7.12529183 L15,10.1523968 L11.972895,7.12529183 Z"
              id="Path"
              stroke="url(#linearGradient-1)"
              strokeWidth="2"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Heart
