import React, { useEffect, useState } from "react";
import AuthContext from "State/authContext";
import getFirebaseInstance from "Utils/firebase";
import SearchProvider from "Components/SearchProvider";
import XupNav from "Components/XupNav";
import cx from "classnames";
import { AuthStatus } from "Constants";

export default function Layout({ location, children }) {
  return (
    <AuthProvider>
      <SearchProvider>
        <App location={location}>{children}</App>
      </SearchProvider>
    </AuthProvider>
  );
}

function App({ location, children }) {
  return (
    <>
      <XupNav />
      <main
        className={cx("app", {
          "--lists": location.pathname.includes("lists"),
        })}
      >
        {children}
      </main>
    </>
  );
}

function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({});
  const [firebaseInstance, setFirebaseInstance] = useState();

  useEffect(() => {
    setFirebaseInstance(getFirebaseInstance());
  }, []);

  useEffect(() => {
    if (!firebaseInstance) {
      return;
    }
    firebaseInstance.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          setAuthState({ status: AuthStatus.In, user, token });
        } else {
          // Check if refresh is required.
          const metadataRef = firebaseInstance.database().ref(`metadata/${user.uid}/refreshTime`);

          metadataRef.on("value", async (data) => {
            if (!data.exists) return;
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            setAuthState({ status: AuthStatus.In, user, token });
          });
        }
      } else {
        setAuthState({ status: AuthStatus.Out });
      }
    });
  }, [firebaseInstance]);

  return <AuthContext.Provider value={{ authState, setAuthState, firebaseInstance }}>{children}</AuthContext.Provider>;
}
