import React from 'react'

const Profile = (props) => (
  <svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
    <title>Login</title>
    <defs>
      <circle id="path-1" cx="20" cy="20" r="18"></circle>
    </defs>
    <g
      id="icon-/-profile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
      <mask id="mask-2" fill="white">
        <use href="#path-1"></use>
      </mask>
      <circle stroke="#38383F" strokeWidth="2" cx="20" cy="20" r="17"></circle>
      <circle
        id="Oval"
        stroke="#38383F"
        strokeWidth="2"
        mask="url(#mask-2)"
        cx="20"
        cy="38"
        r="13"
      ></circle>
      <circle
        id="Oval"
        stroke="#38383F"
        strokeWidth="2"
        mask="url(#mask-2)"
        cx="20"
        cy="15"
        r="7"
      ></circle>
    </g>
  </svg>
)

export default Profile
