import React from 'react'

const SvgCheck = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>{'Check'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        d="M15.366 3.346a.925.925 0 011.281-.157.885.885 0 01.236 1.147l-.075.11L7.65 16l-4.384-4.302a.884.884 0 010-1.267.926.926 0 011.19-.087l.102.087 2.92 2.865 7.887-9.95z"
        fill="#FCFCFC"
      />
    </g>
  </svg>
)

export default SvgCheck
