import React from "react";
import useWindowWidth from "Hooks/useWindowWidth";
import { Link } from "gatsby";
import cx from "classnames";
import ArrowDiagonal from "Svg/ArrowDiagonal";
import Chevron from "Svg/Chevron";
import FacebookIcon from "Svg/FacebookIcon";
import InstagramIcon from "Svg/InstagramIcon";
import styles from "./xupfooter.module.scss";
// Components
import FeedbackButton from "Components/FeedbackButton";
import ContactModal from "Components/Contact";

export default function XupFooter() {
  let width = useWindowWidth();
  return (
    <>
      <footer className={cx(styles.footer)}>
        <div className={styles.footerLeft}>
          <p>© {new Date().getFullYear()} XUP, Inc.</p>
        </div>
        <div className={styles.footerRight}>
          <ul className={styles.list}>
            <li>
              <Link to="/legal/terms-of-service">Legal Shit</Link>
            </li>
            <li>
              <a>
                <ContactModal />
              </a>
            </li>
            <li>
              <a href="https://xupapp.com" alt="XUP">
                xupapp.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <footer className={styles.footerM}>
        {/* <FooterBG className={styles.footerBG} /> */}
        <div className={styles.linksM}>
          <ul>
            {/* <li>
              <FeedbackButton />
            </li> */}
            <li>
              <a href="https://instagram.com/xup.app?igshid=1j84b84tpr9a8" alt="Instagram">
                View the XUP Instagram <InstagramIcon className={styles.social} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/whatsxup/" alt="facebook">
                View the XUP Facebook <FacebookIcon className={styles.social} />
              </a>
            </li>
            <li>
              <Link to="/legal/terms-of-service">
                Legal Shit <Chevron className={styles.chevron} />
              </Link>
            </li>
            <li>
              <a>
                <ContactModal />
                <Chevron className={styles.chevron} />
              </a>
            </li>
            <li>
              <a href="https://xupapp.com" alt="Xup-App">
                xupapp.com
                <ArrowDiagonal className={styles.diagonal} />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.copyrightM}>
          <p>© {new Date().getFullYear()} XUP, Inc.</p>
        </div>
      </footer>
    </>
  );
}
