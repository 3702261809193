import React from 'react'

const SvgInstagram = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    {...props}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M11 0C8.013 0 7.638.013 6.465.066c-1.171.054-1.97.24-2.67.512a5.392 5.392 0 0 0-1.949 1.268A5.392 5.392 0 0 0 .578 3.795c-.272.7-.458 1.499-.512 2.67C.013 7.638 0 8.013 0 11s.013 3.362.066 4.535c.054 1.171.24 1.97.512 2.67a5.392 5.392 0 0 0 1.268 1.949 5.392 5.392 0 0 0 1.949 1.269c.7.271 1.499.457 2.67.51C7.638 21.988 8.013 22 11 22s3.362-.013 4.535-.066c1.171-.054 1.97-.24 2.67-.511a5.392 5.392 0 0 0 1.949-1.27 5.392 5.392 0 0 0 1.269-1.948c.271-.7.457-1.499.51-2.67.054-1.173.067-1.548.067-4.535s-.013-3.362-.066-4.535c-.054-1.171-.24-1.97-.511-2.67a5.392 5.392 0 0 0-1.27-1.949A5.392 5.392 0 0 0 18.206.578c-.7-.272-1.499-.458-2.67-.512C14.362.013 13.987 0 11 0zm0 1.982c2.937 0 3.285.011 4.445.064 1.072.049 1.655.228 2.042.379.514.2.88.438 1.265.823.385.385.624.751.823 1.265.15.387.33.97.379 2.042.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.285-.064 4.445-.049 1.072-.228 1.655-.379 2.042-.2.514-.438.88-.823 1.265a3.408 3.408 0 0 1-1.265.823c-.387.15-.97.33-2.042.379-1.16.053-1.508.064-4.445.064-2.937 0-3.285-.011-4.445-.064-1.072-.049-1.655-.228-2.042-.379-.514-.2-.88-.438-1.265-.823a3.408 3.408 0 0 1-.823-1.265c-.15-.387-.33-.97-.379-2.042-.053-1.16-.064-1.508-.064-4.445 0-2.937.011-3.285.064-4.445.049-1.072.228-1.655.379-2.042.2-.514.438-.88.823-1.265a3.408 3.408 0 0 1 1.265-.823c.387-.15.97-.33 2.042-.379 1.16-.053 1.508-.064 4.445-.064zm0 12.685a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334zm0-9.316a5.649 5.649 0 1 0 0 11.298A5.649 5.649 0 0 0 11 5.35zm7.192-.223a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0"
      fill="#38383F"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgInstagram
