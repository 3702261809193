import React from 'react'

const XupLists = (props) => (
  <svg width={71} height={30} viewBox="0 0 71 30" {...props}>
    <title>{'XUP LISTS'}</title>
    <defs>
      <path
        d="M6.213 10.976l4.863 7.294a.5.5 0 01-.416.778H.934a.5.5 0 01-.416-.778l4.863-7.294a.5.5 0 01.832 0z"
        id="prefix__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 5)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#6750AE" xlinkHref="#prefix__a" />
        <path
          d="M7.885 13.483l3.71 5.565H5.977a27.08 27.08 0 011.638-4.976l.269-.59z"
          fill="#3DD7D5"
          mask="url(#prefix__b)"
        />
        <path
          d="M4.537 12.244a27.333 27.333 0 011.422 6.803H0l4.537-6.803z"
          fill="#E76161"
          mask="url(#prefix__b)"
        />
        <path
          d="M5.381 8.072L.518.777A.5.5 0 01.934 0h9.726a.5.5 0 01.416.777L6.213 8.072a.5.5 0 01-.832 0zM9.745.802H1.849a.2.2 0 00-.166.31L5.63 7.034a.2.2 0 00.333 0L9.91 1.112a.2.2 0 00-.166-.31z"
          fill="#38383F"
        />
      </g>
      <path
        d="M32.235 24.048h-3.28a.5.5 0 01-.43-.244L24.79 17.56a.5.5 0 00-.858 0l-3.735 6.244a.5.5 0 01-.43.244h-3.021a.5.5 0 01-.424-.766l5.503-8.793a.5.5 0 000-.53l-5.112-8.194A.5.5 0 0117.137 5h3.147a.5.5 0 01.432.248l3.412 5.856a.5.5 0 00.866-.003l3.343-5.85A.5.5 0 0128.77 5h3.048a.5.5 0 01.426.762l-5.183 8.414a.5.5 0 00.007.535l5.585 8.563a.5.5 0 01-.42.774zM50.325 5.5v11.659c0 1.388-.306 2.604-.917 3.65-.612 1.045-1.496 1.846-2.652 2.403-1.156.557-2.523.836-4.1.836-2.38 0-4.228-.62-5.544-1.858-1.316-1.238-1.974-2.932-1.974-5.083V5.5a.5.5 0 01.5-.5h2.91a.5.5 0 01.5.5v11.003c0 1.448.287 2.51.861 3.188.574.676 1.523 1.015 2.848 1.015 1.282 0 2.213-.34 2.79-1.022.578-.681.867-1.75.867-3.207V5.5a.5.5 0 01.5-.5h2.911a.5.5 0 01.5.5zm7.552 8.343h.793c1.209 0 2.113-.242 2.713-.727.6-.484.9-1.188.9-2.114 0-.934-.252-1.624-.754-2.07-.503-.445-1.291-.667-2.364-.667h-1.288a.5.5 0 00-.5.5v4.578a.5.5 0 00.5.5zm8.376-2.902c0 2.05-.624 3.618-1.87 4.703-1.247 1.086-3.019 1.629-5.317 1.629H57.88a.5.5 0 00-.5.5v5.775a.5.5 0 01-.5.5h-2.93a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h5.42c2.248 0 3.957.497 5.127 1.492 1.17.994 1.756 2.477 1.756 4.449z"
        fill="#38383F"
      />
      <path
        d="M69 8a1.988 1.988 0 001.838-1.209C70.946 6.55 71 6.285 71 6c0-.285-.054-.549-.162-.791a2.08 2.08 0 00-.434-.636A1.988 1.988 0 0069 4c-.276 0-.535.05-.778.15-.242.1-.454.242-.636.423A1.982 1.982 0 0067 6a1.982 1.982 0 002 2zm0-.388a1.555 1.555 0 01-1.126-.462 1.53 1.53 0 01-.323-.48 1.42 1.42 0 01-.117-.563h0v-.214c0-.194.04-.382.117-.563a1.53 1.53 0 01.818-.815c.192-.085.402-.127.631-.127a1.555 1.555 0 011.126.462c.138.139.246.299.323.48.078.181.117.369.117.563h0v.214c0 .194-.04.382-.117.563a1.53 1.53 0 01-.818.815 1.555 1.555 0 01-.631.127zm-.354-.563v-.777H69l.444.777h.455l-.495-.826a.65.65 0 00.283-.213.607.607 0 00.111-.379.594.594 0 00-.172-.442c-.114-.113-.272-.17-.474-.17h0-.91v2.03h.404zm.485-1.088h-.485V5.34h.485c.155 0 .233.068.233.204h0v.213c0 .136-.078.204-.233.204h0z"
        stroke="#38383F"
        strokeWidth={0.1}
        fill="#38383F"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default XupLists
