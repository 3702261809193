import React, { useState } from "react";
import useWindowWidth from "Hooks/useWindowWidth";
import useAuthState from "Hooks/useAuthState";
import firebase from "firebase/app";
import Moment from "moment";
import { Link, navigate } from "gatsby";
import Dropdown from "react-dropdown";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FeedbackSocial } from "Features/Home/BrandAside";
import XupFooter from "Components/XupFooter";
import cx from "classnames";
import { document, console, Object } from "window-or-global";
import { AuthStatus, Provider } from "Constants";
import Apple from "Svg/Apple";
import Chevron from "Svg/Chevron";
import BackArrowIcon from "Svg/BackArrowIcon";
import BigRedButton from "Svg/BigRedButton";
import Dismiss from "Svg/Dismiss";
import FacebookLogin from "Svg/FacebookLogin";
import Google from "Svg/Google";
import Profile from "Svg/Profile";
import "react-dropdown/style.css";
import "react-toggle/style.css";
import styles from "./profile.module.scss";
import "./profile.scss";
import profileSvg from "Svg/profile.svg";
import PersistToggle from "Components/PersistToggle";

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider(Provider.Apple);

// const providerIdMap = {
//   [Provider.Facebook]: facebookProvider,
//   [Provider.Google]: googleProvider,
//   [Provider.Apple]: appleProvider,
// }

export default function LoginContainer() {
  const { authState, setAuthState, firebaseInstance } = useAuthState();

  if (firebaseInstance) {
    return <Login authState={authState} setAuthState={setAuthState} firebaseInstance={firebaseInstance} />;
  }

  return null;
}

export function LoginButtons({ firebaseInstance }) {
  const [error, setError] = useState();
  const signInWithGoogle = async () => {
    try {
      await firebaseInstance.auth().signInWithPopup(googleProvider);
    } catch (error) {
      setError(error);
    }
  };

  const signInWithFacebook = async () => {
    try {
      await firebaseInstance.auth().signInWithPopup(facebookProvider);
    } catch (error) {
      setError(error);
    }
  };
  const signInWithApple = async () => {
    try {
      await firebaseInstance.auth().signInWithPopup(appleProvider);
    } catch (error) {
      setError(error);
    }
  };
  let isLinked = false;
  return (
    <div className="login">
      {isLinked && <p>Account already exists. Sign in with another provider and link the accounts.</p>}
      <button className="loginButton facebook" onClick={signInWithFacebook}>
        <FacebookLogin />
        Sign in with Facebook
      </button>
      <button className="loginButton google" onClick={signInWithGoogle}>
        <Google />
        Sign in with Google
      </button>
      <button className="loginButton apple" onClick={signInWithApple}>
        <Apple />
        Sign in with Apple
      </button>
      {error && <p>Error: {JSON.stringify(error)}</p>}
    </div>
  );
}

function Login({ authState, setAuthState, firebaseInstance }) {
  const [error, setError] = useState();

  const signOut = async () => {
    try {
      setAuthState({ status: AuthStatus.Loading });
      await firebaseInstance.auth().signOut();
      setAuthState({ status: AuthStatus.Out });
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const [deleted, setDeleted] = useState();
  const [hasta, setHasta] = useState();

  const deleteAccount = async () => {
    try {
      setAuthState({ status: AuthStatus.Loading });
      setDeleted(true);
      await firebaseInstance.auth().currentUser.delete();
    } catch (error) {
      console.log("Error deleting user:", error);
      setError("Error deleting user:", error);
    }
  };

  const submitRef = React.createRef();

  const linkAccount = async (provider) => {
    try {
      const { user, ...rest } = await firebaseInstance.auth().currentUser.linkWithPopup(provider);
      console.log(user, rest);
      setAuthState({
        ...authState,
        status: AuthStatus.In,
        user,
        token: user.getIdTokenResult(),
      });
    } catch (error) {
      setError(error);
    }
  };

  function createdAt() {
    const unformatted = authState.user.metadata?.creationTime;
    const formatted = new Moment(unformatted);
    return formatted.format("MM/DD/YYYY");
  }

  // const transitions = useTransition(clicked, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });
  const unlinkAccount = async (providerId) => {
    try {
      setAuthState({ ...authState, status: AuthStatus.Loading });
      const { user } = await firebaseInstance.auth().currentUser.unlink(providerId);
      setAuthState({ ...authState, status: AuthStatus.In });
    } catch (error) {
      setError(error);
    }
  };
  function openModal() {
    setIsOpen(true);
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const {authState, setAuthState, firebaseInstance} = useAuthState();
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  var subtitle;

  function closeModal() {
    setIsOpen(false);
  }
  let width = useWindowWidth();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "30px",
    },
  };
  const toggle = document.querySelector("#signedToggle");

  function StaySignedIn() {
    const staySigned = false;
  }

  if (authState.status === AuthStatus.Loading) {
    return (
      <div>
        <p>Loading...</p>
        {error && <p>{JSON.stringify(error)}</p>}
      </div>
    );
  }

  // If logged in and has token
  if (authState.status === AuthStatus.In && authState.token) {
    // Look for auth providers
    const facebookAuth = authState.user?.providerData.find((provider) => provider.providerId === Provider.Facebook);
    const googleAuth = authState.user?.providerData.find((provider) => provider.providerId === Provider.Google);

    const appleAuth = authState.user?.providerData.find((provider) => provider.providerId === Provider.Apple);

    function onlyOne() {
      const oneProvider = authState.user?.providerData.length;
      if (oneProvider === 1) {
        return true;
      } else {
        return false;
      }
    }

    function ProviderFacebook() {
      if (facebookAuth) {
        return (
          <div className={styles.facebookAuth}>
            <section className={styles.providerLogoWrapper}>
              <FacebookLogin className={cx(styles.providerLogo, styles.FB)} />
              <p className={styles.providerText}>Facebook</p>
            </section>
            <section className={styles.userName}>
              <h4>Facebook username</h4>
              <p>{facebookAuth?.displayName}</p>
            </section>
            <section className={styles.stay}>
              <h4>Date linked</h4>
              <p>{createdAt()}</p>
            </section>
            <section className={styles.signLink}>
              {facebookAuth ? (
                <button
                  disabled={!facebookAuth}
                  onClick={() => unlinkAccount(Provider.Facebook)}
                  className={onlyOne() ? styles.unlink : ""}
                >
                  Unlink
                </button>
              ) : (
                <button onClick={() => linkAccount(facebookProvider)}>Link Facebook</button>
              )}
            </section>
          </div>
        );
      } else {
        return null;
      }
    }

    function ProviderGoogle() {
      if (googleAuth) {
        return (
          <div className={styles.googleAuth}>
            <section className={styles.providerLogoWrapper}>
              <Google className={styles.providerLogo} />
              <p className={styles.providerText}>Google</p>
            </section>
            <section className={styles.userName}>
              <h4>Google username</h4>
              <p>{googleAuth.displayName}</p>
            </section>
            <section className={styles.stay}>
              <h4>Date linked</h4>
              <p>{createdAt()}</p>
            </section>
            <section className={styles.signLink}>
              {googleAuth ? (
                <button
                  disabled={!googleAuth}
                  onClick={() => unlinkAccount(Provider.Google)}
                  className={onlyOne() ? styles.unlink : ""}
                >
                  Unlink
                </button>
              ) : (
                <button onClick={() => linkAccount(googleProvider)}>Link Google</button>
              )}
            </section>
          </div>
        );
      } else {
        return null;
      }
    }

    function ProviderApple() {
      if (appleAuth) {
        return (
          <div className={styles.appleAuth}>
            <section className={styles.providerLogoWrapper}>
              <Apple className={styles.providerLogo} />
              <p className={styles.providerText}>Apple</p>
            </section>
            <section className={styles.userName}>
              <h4>Apple username</h4>
              <p>{appleAuth.displayName}</p>
            </section>
            <section className={styles.stay}>
              <h4>Date linked</h4>
              <p>{createdAt()}</p>
            </section>

            <section className={styles.signLink}>
              {appleAuth ? (
                <button
                  disabled={!appleAuth}
                  onClick={() => unlinkAccount(Provider.Apple)}
                  className={onlyOne() ? styles.unlink : ""}
                >
                  Unlink
                </button>
              ) : (
                <button onClick={() => linkAccount(appleProvider)}>Link Apple</button>
              )}
            </section>
          </div>
        );
      } else {
        return null;
      }
    }

    function facebookPhoto() {
      const result = [];
      Object.keys(authState.user.providerData).forEach(function (key) {
        if (authState.user.providerData[key]?.providerId === "facebook.com") {
          result.push(authState.user.providerData[key]?.photoURL + "?width=600&height=600");
        }
      });
      return result;
    }

    function applePhoto() {
      const result = [];
      Object.keys(authState.user.providerData).forEach(function (key) {
        if (authState.user.providerData[key]?.providerId === "apple.com") {
          result.push(profileSvg);
        }
      });
      return result;
    }

    function googlePhoto() {
      const result = [];
      Object.keys(authState.user.providerData).forEach(function (key) {
        if (authState.user.providerData[key]?.providerId === "google.com") {
          result.push(authState.user.providerData[key]?.photoURL);
        }
      });
      return result;
    }

    function getPhoto() {
      console.log(authState.user.providerData);
      if (authState.user.providerData.length === 1) {
        if (authState.user.providerData[0].providerId === "apple.com") {
          return profileSvg;
        }
        if (authState.user.providerData[0].providerId === "facebook.com") {
          return authState.user.providerData[0].photoURL + "?width=600";
        }
        if (authState.user.providerData[0].providerId === "google.com") {
          return authState.user.providerData[0].photoURL;
        }
      }
      if (authState.user.providerData.length == 2) {
        if (
          authState.user.providerData[0].providerId === "apple.com" &&
          authState.user.providerData[1].providerId === "facebook.com"
        ) {
          return authState.user.providerData[1]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "google.com" &&
          authState.user.providerData[1].providerId === "apple.com"
        ) {
          return authState.user.providerData[0]?.photoURL;
        }
        if (
          authState.user.providerData[0].providerId === "facebook.com" &&
          authState.user.providerData[1].providerId === "apple.com"
        ) {
          return authState.user.providerData[0]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "apple.com" &&
          authState.user.providerData[1].providerId === "google.com"
        ) {
          return authState.user.providerData[1]?.photoURL;
        }
      }
      if (authState.user.providerData.length === 3) {
        if (
          authState.user.providerData[0].providerId === "apple.com" &&
          authState.user.providerData[1].providerId === "facebook.com" &&
          authState.user.providerData[2].providerId === "google.com"
        ) {
          return authState.user.providerData[1]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "apple.com" &&
          authState.user.providerData[1].providerId === "google.com" &&
          authState.user.providerData[2].providerId === "facebook.com"
        ) {
          return authState.user.providerData[2]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "google.com" &&
          authState.user.providerData[1].providerId === "apple.com" &&
          authState.user.providerData[2].providerId === "facebook.com"
        ) {
          return authState.user.providerData[2]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "google.com" &&
          authState.user.providerData[1].providerId === "facebook.com" &&
          authState.user.providerData[2].providerId === "apple.com"
        ) {
          return authState.user.providerData[1]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "facebook.com" &&
          authState.user.providerData[1].providerId === "apple.com" &&
          authState.user.providerData[2].providerId === "google.com"
        ) {
          return authState.user.providerData[0]?.photoURL + "?width=600";
        }
        if (
          authState.user.providerData[0].providerId === "facebook.com" &&
          authState.user.providerData[1].providerId === "google.com" &&
          authState.user.providerData[2].providerId === "apple.com"
        ) {
          return authState.user.providerData[0]?.photoURL + "?width=600";
        }
      }
    }

    // const stringToPersistance = (persistence = 'LOCAL') => {
    //   switch (persistence) {
    //     case 'LOCAL':
    //       return firebase.auth.Auth.Persistence.LOCAL;
    //     case 'SESSION':
    //       return firebase.auth.Auth.Persistence.SESSION;
    //     case 'NONE':
    //       return firebase.auth.Auth.Persistence.NONE;
    //   }
    // };

    // for future redirects (if already logged in)
    const currentUser = () => {
      return new Promise((resolve) => {
        firebaseInstance.auth().onAuthStateChanged((user) => {
          resolve(user);
          console.log("changed");
        });
      });
    };

    function getName() {
      if (authState.user.providerData.length === 1) {
        if (authState.user.providerData[0]?.providerId === "apple.com") {
          return "XUP User";
        } else {
          return authState.user.providerData[0]?.displayName;
        }
      } else {
        return (
          authState.user.providerData[0]?.displayName ||
          authState.user.providerData[1]?.displayName ||
          authState.user.providerData[2]?.displayName
        );
      }
    }

    // function onlyOneProvider() {
    //   if (data.)
    // }

    const options = ["Account info", "Delete your account"];
    const defaultOption = options[0];

    function _onSelect(options) {
      if (options.value === "Account info") {
        document.getElementById("react-tabs-0").click();
        document.getElementsByClassName("Dropdown-control")[0].style.background = "#6750ae";
      } else if (options.value === "Delete your account") {
        document.getElementById("react-tabs-2").click();
        document.getElementsByClassName("Dropdown-control")[0].style.background = "#e76161";
      } else {
      }
    }

    const arrowClosed = <Chevron className="arrow-open" />;
    const arrowOpen = <Dismiss className="arrow-closed" />;

    function onlyApple() {
      if (authState.user?.providerData.length === 1 && authState.user?.providerData[0].providerId === "apple.com") {
        return true;
      } else {
        return false;
      }
    }

    return (
      <div className={styles.theLoginDiv}>
        <section className={styles.tabs}>
          <Tabs defaultTab="vertical-tab-zero" vertical className={styles.verticalTabs}>
            <TabList className={styles.aside}>
              <section className={styles.content}>
                <header>
                  <Link to="/">
                    <BackArrowIcon />
                  </Link>
                  <h2 className={styles.accountTitle}>Account</h2>
                </header>
                {width > 1024 ? null : (
                  <Dropdown
                    onChange={_onSelect}
                    className={styles.dropdown}
                    controlClassName={styles.dropdownControl}
                    menuClassName={styles.menuItems}
                    options={options}
                    value={defaultOption}
                    placeholder="Select an option"
                    arrowOpen={arrowOpen}
                    arrowClosed={arrowClosed}
                    arrowClassName="arrow"
                  />
                )}
                <Tab
                  tabFor="vertical-tab-zero"
                  className={styles.buttonAccount}
                  selectedClassName={styles.accountSelected}
                >
                  Account info
                </Tab>
                {/* <Tab
                  tabFor="vertical-tab-two"
                  className={styles.buttonContact}
                  selectedClassName={styles.contactSelected}
                >
                  Contact preferences
                </Tab> */}
                <Tab
                  tabFor="vertical-tab-one"
                  className={styles.buttonDelete}
                  selectedClassName={styles.deleteSelected}
                >
                  Delete your account
                </Tab>
                <footer className={styles.loginfeedback}>
                  <FeedbackSocial />
                </footer>
              </section>
            </TabList>
            <TabPanel
              className={styles.accountTab}
              tabId="vertical-tab-one"
              selectedClassName={styles.accountTabSelected}
            >
              <section className={styles.account}>
                <h1>This is what we know about you, {authState.user.displayName}</h1>
                <p className={styles.what}>
                  We got this information from the service you chose to sign in with. And we’re showing you everything
                  we have because we’re transparent af.
                </p>
                {authState.status === AuthStatus.Loading && <p>Loading...</p>}
                <section className={styles.userInfo}>
                  <div class={styles.info}>
                    {onlyApple ? (
                      <img className={styles.profilePic} src={getPhoto()} alt={authState.user?.displayName} />
                    ) : null}

                    <div class={styles.deets}>
                      <p className={styles.name}>{getName()}</p>
                      <a href={"mailto:" + authState.user.email}> {authState.user.email}</a>
                    </div>
                  </div>
                </section>
                <h2>Your linked accounts</h2>
                <section className={styles.accountActions}>
                  <label htmlFor="stay-signed-in-toggle">
                    Stay Signed In
                    <PersistToggle id="stay-signed-in-toggle" firebaseInstance={firebaseInstance} />
                  </label>
                  <Link to="/" className={styles.signOutButton} onClick={signOut}>
                    Sign out now
                  </Link>
                </section>
                <section className={styles.accountSection}>
                  <ProviderFacebook />
                  <ProviderGoogle />
                  <ProviderApple />
                </section>
                <section className={styles.differentService}>
                  {facebookAuth && appleAuth && googleAuth ? null : (
                    <section className={styles.thisText}>
                      <h4>Connect to a different service</h4>
                      <p>Because the more the merrier.</p>
                    </section>
                  )}
                  <section className={styles.serviceButtons}>
                    {facebookAuth ? null : (
                      <button
                        className={cx(styles.linkButton, styles.linkButtonFB)}
                        onClick={() => linkAccount(facebookProvider)}
                      >
                        <FacebookLogin /> Link Facebook
                      </button>
                    )}

                    {googleAuth ? null : (
                      <button
                        className={cx(styles.linkButton, styles.linkButtonG)}
                        onClick={() => linkAccount(googleProvider)}
                      >
                        <Google /> Link Google
                      </button>
                    )}

                    {appleAuth ? null : (
                      <button
                        className={cx(styles.linkButton, styles.linkButtonA)}
                        onClick={() => linkAccount(appleProvider)}
                      >
                        <Apple /> Link Apple
                      </button>
                    )}
                  </section>
                </section>
                <section className={styles.bottomFooter}>
                  <XupFooter />
                </section>
              </section>
            </TabPanel>
            {/* <TabPanel
              className={styles.cotactTab}
              selectedClassName={styles.contactTabSelected}
              tabId="vertical-tab-two"
            >
              <section classname={styles.contactInfo}>
                <h2>Control how much we contact you</h2>
                <p className={styles.mainText}>
                  We never want to pesture you with our infrequent email
                  communications. So here you can choose exactly which
                  communications you would like to get from us. You may still
                  receive account and support related emails.
                </p>
                <p className={styles.emailText}>
                  Your email is {authState.user.email}
                </p>
                <hr />
                <section className={styles.emailPrefs}>
                  <h2>Email Preferences</h2>
                  <div className={styles.toggleWrapper}>
                    <div className={styles.textWrapper}>
                      <h4>Send me XUPDATES</h4>
                      <p>Important company milestones and announcments</p>
                    </div>
                    <Toggle
                      id="XUPDATEToggle"
                      defaultChecked={false}
                      onChange={!StaySignedIn}
                      icons={false}
                      className="signedToggle"
                    />
                  </div>
                  <div className={styles.toggleWrapper}>
                    <div className={styles.textWrapper}>
                      <h4>Send me event invitations</h4>
                      <p>Event announcments</p>
                    </div>
                    <Toggle
                      id="eventToggle"
                      defaultChecked={false}
                      onChange={!StaySignedIn}
                      icons={false}
                      className="signedToggle"
                    />
                  </div>
                  <div className={styles.toggleWrapper}>
                    <div className={styles.textWrapper}>
                      <h4>Send me promotions</h4>
                      <p>XUP friends promotions and deals</p>
                    </div>
                    <Toggle
                      id="promoToggle"
                      defaultChecked={false}
                      onChange={!StaySignedIn}
                      icons={false}
                      className="signedToggle"
                    />
                  </div>
                  <a>Don't send me any emails please</a>
                  <p className={styles.subtitle}>
                    You'll still receive administrative emails.
                  </p>
                </section>
              </section>
              <section className={styles.bottomFooter}>
                <XupFooter />
              </section>
            </TabPanel>
             */}
            <TabPanel
              className={styles.deleteTab}
              selectedClassName={styles.deleteTabSelected}
              tabId="vertical-tab-two"
            >
              <div class={styles.contentWrapper}>
                <h2>We get it, it’s not us, it’s you</h2>
                <p className={styles.text}>
                  By pressing the button below, you will completely delete your account. All of your account data will
                  be erased. You cannot reverse this action, but you can always create a new account whenever you’d
                  like.
                </p>
                <section className={styles.deleteButton}>
                  <BigRedButton onClick={openModal} />
                  <h3>press the button to delete your account</h3>

                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    styles={customStyles}
                    className={styles.deleteModal}
                    overlayClassName={styles.overlay}
                    contentLabel="Example Modal"
                  >
                    <>
                      {hasta ? (
                        <section className={styles.hastaWrapper}>
                          <Dismiss className={styles.close} onClick={closeModal} />
                          <section className={styles.hastaBox}>
                            <p>hasta la vista, baby</p>
                            <div className={styles.triangle} />
                          </section>
                          <p>
                            Your account has been deleted.
                            <br />
                            The site will reload now.
                          </p>
                        </section>
                      ) : (
                        <>
                          <section className={styles.modalContent}>
                            <header>
                              <span>
                                <Profile />
                                Delete account
                              </span>
                              <Dismiss className={styles.dissmiss} onClick={closeModal} />
                            </header>
                            <h2>Are you absolutely sure?</h2>
                            {width <= 767 && (
                              <p>
                                By confirming below, you will completely delete your account. All of your account data
                                will be erased. You cannot reverse this action, but you can always create a new account
                                whenever you’d like.
                              </p>
                            )}
                          </section>
                          <button
                            className={styles.deleteButtonFinal}
                            onClick={() => {
                              setHasta(true);
                              setTimeout(() => {
                                deleteAccount().then(navigate("/"));
                              }, 3000);
                            }}
                          >
                            Yes dude
                          </button>
                        </>
                      )}
                    </>
                  </Modal>
                </section>
              </div>
              <section className={styles.bottomFooter}>
                <XupFooter />
              </section>
            </TabPanel>
          </Tabs>
        </section>
      </div>
    );
  } else {
    return null;
  }
}
