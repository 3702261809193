import React, { useRef, useState } from "react";
import Modal from "react-modal";
import cx from "classnames";
import Dissmiss from "Svg/Dismiss";
import styles from "./really.module.scss";

export default function FeedbackButton() {
  // Hooks
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // Open the Modal
  function openModal() {
    setIsOpen(true);
  }
  // Close Modal
  function closeModal(props) {
    setIsOpen(false);
  }
  // Custom Styles (can override)
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(233,231,231,0.6",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <button className={styles.whatifButton} onClick={openModal}>
        What if you don't have these services?
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        styles={customStyles}
        className={styles.reallyModal}
        overlayClassName={styles.reallOverlay}
        portalClassName={styles.reallyPortal}
        contentLabel="What if you don't have these services?"
        style={{
          overlay: {
            backgroundColor: "rgba(233,231,231,0.6",
          },
        }}
      >
        <section className={styles.content}>
          <header>
            <h2>What if you don't have these services?</h2>
            <button className={styles.close} onClick={closeModal}>
              <Dissmiss />
            </button>
          </header>
          <section className={styles.list}>
            <ul>
              <li>Really?</li>
              <li>You're joking right?</li>
              <li>
                In the interest of being as secure as possible for our users, we think it’s best to stick to only these
                services. Perhaps in the future we’ll let you sign in without them. If you’d like to suggest a sign-in
                service, use the "contact us” button on the browse page.
              </li>
            </ul>
          </section>
        </section>
      </Modal>
    </>
  );
}
