import React, { useRef, useState } from "react";
import styles from "./contact.module.scss";
import Modal from "react-modal";
import Dissmiss from "Svg/Dismiss";
import XUPTiny from "Svg/XUPTiny";
import { Formik, Field } from "formik";
import { document, console } from "window-or-global";
import CheckIcon from "Svg/CheckIcon";
import { useTransition, animated } from "react-spring";
import cx from "classnames";
import emailjs from "emailjs-com";
import useAuthState from "Hooks/useAuthState";
import { navigateTo } from "gatsby";

export default function ContactModal({ link = "", variant }) {
  const AnimatedCheckIcon = animated(CheckIcon);
  const [submitted, setSubmitted] = useState(false);

  const { authState } = useAuthState();
  const { loggedIn } = useState();

  const transitions = useTransition(submitted, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  if (typeof window !== "undefined" && !link) {
    link = window.location.pathname;
  }

  let form = useRef(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [buttonActive] = useState(false);
  // const { authState, setAuthState, firebaseInstance } = useAuthState();

  function closeModal(props) {
    setIsOpen(false);
    props = initialState;
    navigateTo(window.history[0]);
  }

  const initialState = { feedback: null, contactCheck: null, radioGroup: null };

  const submitRef = React.useRef();

  function validateFeedback(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    if (value) {
      submitRef.current.classList.add(`${styles.activeButton}`);
    }
    return error;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function handleActive() {
    document.querySelector("form").classList.add(`${styles.active}`);
  }

  function sendSupport(values) {
    emailjs.init("user_LR2tAW4YAWIQFlP2HXLBr");
    let templateId = "template_btaln0a";
    let userID = "user_LR2tAW4YAWIQFlP2HXLBr";
    let variables = {
      message_html: values,
      from_name: "XUP",
      reply_to: "lists@xupapp.com",
    };
    window.emailjs
      .send("gmail", templateId, variables, userID)
      .then((res) => {})
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Oh well, you failed. Here some thoughts on the error that occured:", err));
  }

  const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, onSelect, ...props }) => (
    <div className={styles.radioWrapper}>
      <input
        name={name}
        id={id}
        type="radio"
        value={label}
        checked={label === value}
        onChange={onChange}
        onClick={onSelect}
        onBlur={onBlur}
        className={styles.radio}
        {...props}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );

  const isLoggedIn = authState?.status === "in";

  function SupportData(values) {
    return (
      <section>
        <h1>Support Form:</h1>
        <ul>
          <li>
            How can we help?: <strong>{values.radioGroup}</strong>
          </li>
          <li>
            Feedback: <strong>{values.feedback}</strong>
          </li>
          <li>
            I'd like to be contacted: <strong>{values?.contactCheck}</strong>
          </li>
        </ul>
      </section>
    );
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)} className={cx(styles.supportButton)} id="support">
        Contact Us
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          closeModal();
        }}
        styles={customStyles}
        className={cx(styles.support, styles.animate__bounceInUp)}
        contentLabel="Support Modal"
      >
        <div className={cx(styles.supportModalWrapper, styles.animate__bounceInUp)}>
          <section className={styles.supportModal}>
            <header className={styles.header}>
              <div>
                <XUPTiny className={styles.tiny} />
                <p className={styles.title}>Support</p>
              </div>
              <button className={styles.supportModalDissmiss} onClick={closeModal}>
                <Dissmiss />
              </button>
            </header>
            <div className={styles.titleWrapper}>
              <h3>How can we help you?</h3>
            </div>
            <Formik
              className={styles.supportForm}
              onClick={handleActive}
              // onChange={props => {
              //   console.log(props.values)
              // }}
              useRef={form}
              initialValues={{
                feedback: "",
                contactCheck: null,
                radioGroup: null,
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(false);
                  sendSupport(
                    "How can we help you?: " +
                      values.radioGroup +
                      "<br/>" +
                      "Feedback: " +
                      values.feedback +
                      "<br/>" +
                      "Conact?:" +
                      values.contactCheck
                  );
                }, 1000);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  {/* {props.errors.name && (
                    <div id="support">{props.errors.support}</div>
                  )} */}
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={"wrong"}
                    onSelect={() => console.log(props.values)}
                    label="Something is wrong / incorrect"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={"help"}
                    onSelect={() => console.log(props.values)}
                    label="I need help"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={"hello"}
                    onSelect={() => console.log(props.values)}
                    label="Just want to say hey"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={"other"}
                    onSelect={() => console.log(props.values)}
                    label="Other"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    as="textarea"
                    name="feedback"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.feedback}
                    onClick={handleActive}
                    onTouchStart={handleActive}
                    className={styles.input}
                    validate={validateFeedback}
                    placeHolder="Tell us your deal"
                    {...(submitted ? { disabled: "true" } : {})}
                  ></Field>
                  {isLoggedIn ? (
                    <Field as="checkbox" className={styles.checkBox}>
                      <label>
                        <input
                          name="contactCheck"
                          type="checkbox"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.contactCheck}
                          className={styles.inputCheck}
                        />
                        I’d like to be contacted about this.
                      </label>
                      <p className={styles.checkSub}>We’ll use the email associated with your account.</p>
                    </Field>
                  ) : null}

                  <button
                    className={cx(styles.submitButton, {
                      [styles.submitted]: submitted,
                    })}
                    ref={submitRef}
                    onClick={() => {
                      if (!props.errors.feedback) {
                        setSubmitted(true);
                        sendSupport(<SupportData />);
                      }
                    }}
                    {...(buttonActive ? { disabled: "true" } : {})}
                    type="submit"
                  >
                    {transitions.map(({ item, key, props }) =>
                      item ? <AnimatedCheckIcon className={styles.check} key={key} style={props} /> : null
                    )}
                    {submitted ? "Submitted" : "Submit"}
                  </button>
                </form>
              )}
            </Formik>

            <section className={styles.bottomCaption} style={loggedIn ? { marginTop: "auto!important" } : {}}>
              <div className={styles.line}></div>
              <div className={styles.circle}></div>
              <p>
                XUP will do its best to help you as soon as we can.{" "}
                <span role="img" aria-label="Peace">
                  ✌️
                </span>
                <span role="img" aria-label="Love">
                  🥰
                </span>
                <span role="img" aria-label="XUP">
                  🙅
                </span>
              </p>
            </section>
          </section>
        </div>
      </Modal>
    </>
  );
}
