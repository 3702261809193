import React from "react";

const Dismiss = (props) => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" {...props}>
    <title>Dismiss</title>
    <g id="10-Xperiences-Overview" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="10.10-Overview" transform="translate(-464.000000, -104.000000)">
        <g id="free-shit" transform="translate(50.000000, 90.000000)">
          <g id="icon-/-dismiss" transform="translate(410.000000, 10.000000)">
            <rect id="Rectangle" x="1" y="1" width="18" height="18"></rect>
            <path
              d="M14.9497475,5.05025253 C15.2426407,5.34314575 15.1391426,5.92151761 14.7185779,6.34208223 L11.0596987,9.99969872 L14.7185779,13.6579178 C15.1391426,14.0784824 15.2426407,14.6568542 14.9497475,14.9497475 C14.6568542,15.2426407 14.0784824,15.1391426 13.6579178,14.7185779 L9.99969872,11.0596987 L6.34208223,14.7185779 C5.92151761,15.1391426 5.34314575,15.2426407 5.05025253,14.9497475 C4.75735931,14.6568542 4.86085743,14.0784824 5.28142206,13.6579178 L8.93869872,9.99969872 L5.28142206,6.34208223 C4.86085743,5.92151761 4.75735931,5.34314575 5.05025253,5.05025253 C5.34314575,4.75735931 5.92151761,4.86085743 6.34208223,5.28142206 L9.99969872,8.93869872 L13.6579178,5.28142206 C14.0784824,4.86085743 14.6568542,4.75735931 14.9497475,5.05025253 Z"
              id="Combined-Shape"
              fill="white"
              fillRule="evenodd"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Dismiss;
