import React from 'react'

const Chevron = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>Find out more</title>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        d="M11.49 10l-4.953 5.125a.825.825 0 000 1.14.76.76 0 001.1 0L13.691 10 7.637 3.736a.76.76 0 00-1.1 0 .825.825 0 000 1.139L11.49 10z"
        fill="#FCFCFC"
      />
    </g>
  </svg>
)
export default Chevron
