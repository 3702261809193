import React from 'react'

const SvgFbook = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 11 22" {...props}>
    <path
      d="M11 3.652l-1.994.001c-1.563 0-1.866.772-1.866 1.903v2.497h3.729l-.486 3.912H7.14V22H3.25V11.965H0V8.053h3.251V5.17C3.251 1.822 5.22 0 8.094 0c1.377 0 2.56.106 2.906.154v3.498z"
      fill="#38383F"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgFbook
