import React from 'react'

const ArrowDiagonal = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>{'icon / arrow diagonal'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        d="M4.343 15.657a.792.792 0 01-.019-1.12l6.054-6.054a.792.792 0 011.12.019.792.792 0 01.02 1.12l-6.055 6.054a.792.792 0 01-1.12-.02zM14.1 5.9l-7.126-.122a.825.825 0 01-.806-.805.76.76 0 01.779-.778l8.71.148.148 8.71a.76.76 0 01-.778.779.825.825 0 01-.805-.806L14.1 5.9z"
        fill="#FCFCFC"
      />
    </g>
  </svg>
)

export default ArrowDiagonal
