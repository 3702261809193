export const AuthError = {
  AccountExists: 'auth/account-exists-with-different-credential',
}

export const AuthStatus = {
  In: 'in',
  Out: 'out',
  Loading: 'loading',
}

export const Provider = {
  Facebook: 'facebook.com',
  Google: 'google.com',
  Apple: 'apple.com',
}

const ProviderCopy = {
  [Provider.Facebook]: 'Facebook',
  [Provider.Google]: 'Google',
  [Provider.Apple]: 'Apple',
}
