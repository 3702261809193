import React from "react";
import { wrapper } from "./socialIcon.module.scss";

function SocialIcon({ children, link, ...rest }) {
  return (
    <a title="Opens in new tab" target="_blank" rel="noopener noreferrer" href={link} className={wrapper}>
      {children}
    </a>
  );
}

export default SocialIcon;
