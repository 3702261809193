import React from "react";
import { Link } from "gatsby";
import useAuthState from "Hooks/useAuthState";
//Import Svg
import profileSvg from "Svg/profile.svg";
import XupLists from "Svg/XupLists";
import Profile from "Svg/Profile";
import HeartFilled from "Svg/HeartFilled";
import Heart from "Svg/Heart";
import styles from "./xupNav.module.scss";
import Modal from "react-modal";
import { LoginButtons } from "../../pages/profile";
import Dissmiss from "Svg/Dismiss";
import Login from "Svg/Login";
import heartSvg from "Static/svg/heart.svg";
import hearOutlineSvg from "Static/svg/heartOutline.svg";
import { URLSearchParams } from "window-or-global";
import cx from "classnames";
import "./modal.scss";
// Components
import ReallyModal from "Components/ReallyModal";
import SupportModal from "Components/Support";
import XUPBlack from "Svg/XupBlack";

function HeartUser({ user, link = "" }) {
  if (user) {
    if (link === "/likes/") {
      return (
        <Link to="/likes/" className={styles.navLikeLink}>
          <img src={heartSvg} alt="Liked Xperiences" className={styles.likes} />
          {/* <HeartFilled className={styles.likes} /> */}
          <div className={cx(styles.underline, styles.likeLine)} />
        </Link>
      );
    } else {
      return (
        <Link to="/likes/">
          <img src={hearOutlineSvg} alt="Liked Xperiences" className={styles.likes} />
        </Link>
      );
    }
  } else {
    return null;
  }
}

function UserPic({ user, link = "" }) {
  function openModal() {
    setIsOpen(true);
  }
  if (typeof window !== "undefined" && !link) {
    link = window.location.pathname;
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { authState, setAuthState, firebaseInstance } = useAuthState();
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  var subtitle;

  function closeModal() {
    setIsOpen(false);
  }

  if (user) {
    if (link === "/profile") {
      return (
        <Link to="/profile" className={cx(styles.navigationItem, styles.profilePage)}>
          <img
            className={styles.userPic}
            src={
              user.providerData[2]?.photoURL ||
              user.providerData[1]?.photoURL ||
              user.providerData[0]?.photoURL ||
              profileSvg
            }
            alt={user.displayName}
          />
          <div className={cx(styles.underline, styles.userLine)} />
        </Link>
      );
    }

    return (
      <Link to="/profile" className={cx(styles.navigationItem, styles.profilePage)}>
        <img
          className={styles.userPic}
          src={
            user.providerData[2]?.photoURL ||
            user.providerData[1]?.photoURL ||
            user.providerData[0]?.photoURL ||
            profileSvg
          }
          alt={user.displayName}
        />
      </Link>
    );
  } else {
    return (
      <>
        <button onClick={openModal} className={styles.navigationItem} id="login">
          <Profile className={styles.profile} />
        </button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          onAfterClose={closeModal}
          styles={customStyles}
          className={styles.loginModal}
          overlayClassName={styles.overlay}
          portalClassName={styles.loginPortal}
          contentLabel="Example Modal"
        >
          <Login className={styles.bgLogin} />
          {/* <BrandAsideBG className="bgLoginM" /> */}
          <div className={styles.modalWrapper}>
            <section className={styles.modalContent}>
              <button className={styles.dissmiss} onClick={closeModal}>
                <Dissmiss />
              </button>
              <XUPBlack className={styles.black} />
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>We'll show you ours, if you show us yours.</h2>

              <p className={styles.firstText}>
                Sign in or sign up to like our hand crafted, free-range, locally sourced LISTS.
              </p>
              <LoginButtons firebaseInstance={firebaseInstance} />
              <Link className={styles.helpLink} to="/">
                <SupportModal />
              </Link>
              <p className={styles.disclosure}>
                We use these services instead of our own because we want your information to be as secure as possible.{" "}
                <ReallyModal />
              </p>
            </section>
          </div>
        </Modal>
      </>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export function OpenLoginModal() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  return setIsOpen(true);
}

export default function Navigation() {
  const { authState, setAuthState } = useAuthState();

  return (
    <header className={styles.container}>
      <nav className={styles.navBar}>
        <section className={styles.logoContainer}>
          <Link className={styles.navigationItem} to="/">
            <XupLists className={styles.logo} />
            <section className={styles.listR}>
              <p>LISTS</p>
              <div className={styles.underline} />
            </section>
          </Link>
        </section>
        <section className={styles.userContainer}>
          <UserPic user={authState.user} />
        </section>
      </nav>
    </header>
  );
}
