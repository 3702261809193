import React from 'react'

const BigRedButton = (props) => (
  <svg width={194} height={194} viewBox="0 0 194 194" {...props}>
    <title>{'Group'}</title>
    <defs>
      <filter
        x="-19.8%"
        y="-19.8%"
        width="139.6%"
        height="139.6%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feGaussianBlur stdDeviation={7} in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#AEAFB7" cx={97.5} cy={97.5} r={96.5} />
      <circle fill="#CD5D70" cx={94} cy={94} r={94} />
      <circle fill="#AF4B5C" cx={99} cy={99} r={79} />
      <circle fill="#AF4B5C" cx={92} cy={92} r={79} />
      <circle fill="#E76161" cx={94} cy={94} r={79} />
      <circle fill="#CE5353" filter="url(#prefix__a)" cx={94} cy={94} r={53} />
      <path
        d="M141.36 40.987C155.287 53.953 164 72.449 164 92.98c0 39.223-31.797 71.02-71.02 71.02-20.53 0-39.027-8.712-51.993-22.64 12.682 11.816 29.696 19.044 48.397 19.044 39.223 0 71.02-31.797 71.02-71.02 0-18.435-7.024-35.23-18.541-47.853zM28.674 87.2a56.95 56.95 0 01-.058-2.584c0-28.973 22.002-52.807 50.21-55.704-26.166 7.747-46.03 30.16-50.071 57.716z"
        fill="#EFAFAF"
      />
    </g>
  </svg>
)

export default BigRedButton
