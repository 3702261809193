import React, { useRef, useState } from "react";
import styles from "./feedbackButton.module.scss";
import useWindowWidth from "Hooks/useWindowWidth";
import useWindowHeight from "Hooks/useWindowHeight";
import useAuthState from "Hooks/useAuthState";

import Modal from "react-modal";
import Dissmiss from "Svg/Dismiss";
import XUPTiny from "Svg/XUPTiny";
import Chevron from "Svg/Chevron";
import { Formik, Field } from "formik";
import { document, console, window } from "window-or-global";
import CheckIcon from "Svg/CheckIcon";
import { useTransition, animated } from "react-spring";
import cx from "classnames";
import emailjs from "emailjs-com";

export default function FeedbackButton() {
  const AnimatedCheckIcon = animated(CheckIcon);
  const [submitted, setSubmitted] = useState(false);
  const transitions = useTransition(submitted, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  let width = useWindowWidth();
  let height = useWindowHeight();
  function openModal() {
    setIsOpen(true);
  }
  let form = useRef(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const { authState, setAuthState, firebaseInstance } = useAuthState();
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  var subtitle;

  const submitRef = React.useRef();

  function closeModal(props) {
    setIsOpen(false);
    props = initialState;
  }

  const { authState, setAuthState } = useAuthState();

  function open() {
    if (authState.status === "out") {
      return document.getElementById("login").click();
    } else {
      return openModal();
    }
  }

  function validateFeedback(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    if (value) {
      submitRef.current.classList.add(`${styles.activeButton}`);
    }
    return error;
  }

  const initialState = { feedback: null, contactCheck: null };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function handleActive() {
    document.querySelector("form").classList.add(`${styles.active}`);
  }
  const [buttonActive, setButtonActive] = useState(false);

  function sendFeedback(values) {
    emailjs.init("user_LR2tAW4YAWIQFlP2HXLBr");
    let templateId = "template_btaln0a";
    let userID = "user_LR2tAW4YAWIQFlP2HXLBr";
    let variables = {
      message_html: values,
      from_name: "XUP",
      reply_to: "lists@xupapp.com",
    };
    window.emailjs
      .send("gmail", templateId, variables, userID)
      .then((res) => {})
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Oh well, you failed. Here some thoughts on the error that occured:", err));
  }

  const [dissmissed, setDissmissed] = useState(false);

  const inputRef = useRef(null);

  return (
    (width >= 1025 && (
      <>
        <button onClick={open} className={cx(styles.feedbackButton)}>
          {width > 1200 ? "Give us some feedback" : "Feedback"}
        </button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={() => {
            closeModal();
          }}
          styles={customStyles}
          className={cx(styles.feedback, styles.animate__bounceInUp)}
          contentLabel="Example Modal"
        >
          <div className={cx(styles.feedbackModalWrapper, styles.animate__bounceInUp)}>
            <section className={styles.feedbackModal}>
              <header className={styles.header}>
                <p className={styles.title}>
                  <XUPTiny className={styles.tiny} />
                  Feedback
                </p>
                <button className={styles.feedbackModalDissmiss} onClick={closeModal}>
                  <Dissmiss />
                </button>
              </header>
              <h3 ref={(_subtitle) => (subtitle = _subtitle)}>
                Let us know what you think. You’re what makes XUP awesome. We’re just here to do your bidding.
              </h3>
              <p className={styles.caption}>
                Tell us what you think about the site, about our company, about your last breakup. We’ll happily read it
                and respond if you want us to.
              </p>
              <Formik
                className={styles.feedbackForm}
                onClick={() => {
                  handleActive();
                }}
                useRef={form}
                initialValues={{ feedback: "", contactCheck: "" }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                    sendFeedback("feedback: " + values.feedback);
                  }, 1000);
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    {/* {props.errors.feedback && (
                    <div id="feedback">{props.errors.feedback}</div>
                  )} */}
                    <Field
                      as="textarea"
                      name="feedback"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.feedback}
                      onClick={() => {
                        handleActive();
                      }}
                      onTouchStart={handleActive}
                      className={styles.input}
                      placeHolder="Tell us your deal"
                      validate={validateFeedback}
                      {...(submitted ? { disabled: "true" } : {})}
                    ></Field>
                    <Field as="checkbox" className={styles.checkBox}>
                      <label>
                        <input
                          name="contactCheck"
                          type="checkbox"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.contactCheck}
                          className={styles.inputCheck}
                          {...(submitted ? { disabled: "true" } : {})}
                        />
                        I’d like to be contacted about this.
                      </label>
                      <p className={styles.checkSub}>We’ll use the email associated with your account.</p>
                    </Field>
                    <button
                      className={cx(styles.submitButton, {
                        [styles.submitted]: submitted,
                      })}
                      onClick={() => {
                        if (!props.errors.feedback) {
                          setSubmitted(true);
                          sendFeedback("feedback: " + props.values.feedback);
                        }
                      }}
                      ref={submitRef}
                      {...(buttonActive ? { disabled: "true" } : {})}
                      type="submit"
                    >
                      {transitions.map(({ item, key, props }) =>
                        item ? <AnimatedCheckIcon className={styles.check} key={key} style={props} /> : null
                      )}
                      {submitted ? "Submitted" : "Submit"}
                    </button>
                  </form>
                )}
              </Formik>

              <section className={styles.bottomCaption}>
                <div className={styles.line}></div>
                <div className={styles.circle}></div>
                <p>
                  XUP will not share your feedback with anyone other than the creators of XUP. We appreciate the time
                  you took to give us a shout out and we hope to address your feedback as best as we can. Cheers.
                </p>
              </section>
            </section>
          </div>
        </Modal>
      </>
    )) ||
    (width <= 1024 && (
      <>
        <div className={styles.footerFeedback} onClick={open}>
          Give us some feedback <Chevron className={styles.chevron} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={() => {
            closeModal();
          }}
          styles={customStyles}
          className={cx(styles.feedback, styles.animate__bounceInUp)}
          contentLabel="Example Modal"
        >
          {/* <BrandAsideBG className="bgLoginM" /> */}
          <div className={cx(styles.feedbackModalWrapper, styles.animate__bounceInUp)}>
            <section className={styles.feedbackModal}>
              <header className={styles.header}>
                <p className={styles.title}>
                  <XUPTiny className={styles.tiny} />
                  Feedback
                </p>
                <button className={styles.feedbackModalDissmiss} onClick={closeModal}>
                  <Dissmiss />
                </button>
              </header>
              <h3 ref={(_subtitle) => (subtitle = _subtitle)}>
                Let us know what you think. You’re what makes XUP awesome. We’re just here to do your bidding.
              </h3>
              <p className={styles.caption}>
                Tell us what you think about the site, about our company, about your last breakup. We’ll happily read it
                and respond if you want us to.
              </p>
              <Formik
                className={styles.feedbackForm}
                onClick={() => {
                  handleActive();
                }}
                useRef={form}
                initialValues={{ feedback: "", contactCheck: "" }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                    sendFeedback("feedback: " + values.feedback);
                  }, 1000);
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <Field
                      as="textarea"
                      name="feedback"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.feedback}
                      onClick={() => {
                        handleActive();
                      }}
                      onTouchStart={handleActive}
                      className={styles.input}
                      placeHolder="Tell us your deal"
                      validate={validateFeedback}
                      {...(submitted ? { disabled: "true" } : {})}
                    ></Field>
                    <Field as="checkbox" className={styles.checkBox}>
                      <label>
                        <input
                          name="contactCheck"
                          type="checkbox"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.contactCheck}
                          className={styles.inputCheck}
                          {...(submitted ? { disabled: "true" } : {})}
                        />
                        I’d like to be contacted about this.
                      </label>
                      <p className={styles.checkSub}>We’ll use the email associated with your account.</p>
                    </Field>
                    <button
                      className={cx(styles.submitButton, {
                        [styles.submitted]: submitted,
                      })}
                      onClick={() => {
                        if (!props.errors.feedback) {
                          setSubmitted(true);
                          sendFeedback("feedback: " + props.values.feedback);
                        }
                      }}
                      ref={submitRef}
                      {...(buttonActive ? { disabled: "true" } : {})}
                      type="submit"
                    >
                      {transitions.map(({ item, key, props }) =>
                        item ? <AnimatedCheckIcon className={styles.check} key={key} style={props} /> : null
                      )}
                      {submitted ? "Submitted" : "Submit"}
                    </button>
                  </form>
                )}
              </Formik>

              <section className={styles.bottomCaption}>
                <div className={styles.line}></div>
                <div className={styles.circle}></div>
                <p>
                  XUP will not share your feedback with anyone other than the creators of XUP. We appreciate the time
                  you took to give us a shout out and we hope to address your feedback as best as we can. Cheers.
                </p>
              </section>
            </section>
          </div>
        </Modal>
      </>
    ))
  );
}
