import React from "react";
import Toggle from "react-toggle";
import cx from "classnames";
import styles from "./persist.module.scss";
import useAuthState from "Hooks/useAuthState";
import firebase from "firebase/app";
import { gql, useQuery, useMutation } from "@apollo/client";
import { console } from "window-or-global";

const GET_USER = gql`
  query GET_USER($ail: String!) {
    users(where: { email: { _eq: $ail } }) {
      email
      persist
    }
  }
`;

const SET_PERSIST = gql`
  mutation SET_PERSIST($ail: String!) {
    update_users(where: { email: { _eq: $ail } }, _set: { persist: true }) {
      affected_rows
    }
  }
`;

const SET_PERSIST_FALSE = gql`
  mutation SET_PERSIST_FALSE($ail: String!) {
    update_users(where: { email: { _eq: $ail } }, _set: { persist: false }) {
      affected_rows
    }
  }
`;

export default function PersistToggle(props) {
  // Set Persistance
  const [persist] = React.useState(true);
  const { authState, setAuthState, firebaseInstance } = useAuthState();
  const submitRef = React.createRef();

  let umail = React.useRef(null);
  // const expId = experience.experience._id;
  if (authState.status === "in") {
    umail.current = authState.user.email;
  } else {
    umail.current = "none";
  }

  // Persist True Mutation

  const [onPersist] = useMutation(SET_PERSIST, {
    variables: {
      email: props.ail,
      ail: umail.current,
    },
    context: {
      headers: {
        Authorization: `Bearer ${authState?.token}`,
      },
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          email: props._email,
          ail: umail.current,
        },
        context: {
          headers: {
            Authorization: `Bearer ${authState?.token}`,
          },
        },
      },
    ],
  });

  // SET PERSIST FALSE
  const [noPersist] = useMutation(SET_PERSIST_FALSE, {
    variables: {
      email: props.ail,
      ail: umail.current,
    },
    context: {
      headers: {
        Authorization: `Bearer ${authState?.token}`,
      },
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          email: props._email,
          ail: umail.current,
        },
        context: {
          headers: {
            Authorization: `Bearer ${authState?.token}`,
          },
        },
      },
    ],
  });
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      email: props._email,
      ail: umail.current,
    },
    context: {
      headers: {
        Authorization: `Bearer ${authState?.token}`,
      },
    },
  });
  // if above useQuery data is not loaded
  if (loading) return "Loading...";
  // if data fetch failed
  if (error) return `Error! ${error.message}`;

  async function setPersistance({ firebaseInstance }) {
    if (submitRef.current?.state.checked === true) {
      try {
        await firebaseInstance.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      } catch (error) {
        console.log("Error", error);
        // setError('Error setting persistance', error);
      }
    }
    if (submitRef.current?.state.checked === false) {
      try {
        await firebaseInstance.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } catch (error) {
        console.log("Error setting peristance state:", error);
        // setError('Error setting persistance', error);
      }
    }
  }

  return (
    <>
      {data.users[0]?.persist === false && (
        <>
          <Toggle
            defaultChecked={true}
            onChange={() => {
              setPersistance({
                firebaseInstance,
              });
              onPersist();
            }}
            icons={false}
            className={cx("signedToggle", styles.signedToggle, styles.toggle)}
            id="signedToggle"
            ref={submitRef}
          />
        </>
      )}
      {data.users[0]?.persist === true && (
        <>
          <Toggle
            defaultChecked={false}
            onChange={() => {
              setPersistance({
                firebaseInstance,
              });
              noPersist();
            }}
            icons={false}
            className={cx("signedToggle", styles.signedToggle, styles.toggle)}
            id="signedToggle"
            ref={submitRef}
          />
        </>
      )}
    </>
  );
}
