import React from 'react'

const XUPTiny = (props) => (
  <svg viewBox="0 0 11.594 19.048" {...props}>
    <path
      fill="#fcfcfc"
      fillRule="evenodd"
      d="M5.797 10.352l5.797 8.696H0l5.797-8.696z"
    />
    <path
      d="M7.885 13.483l3.71 5.565H5.978a27.078 27.078 0 011.639-4.976zM4.537 12.244a27.336 27.336 0 011.422 6.803H0zM5.797 8.696L0 0h11.594zM10.12.802H1.476l4.321 6.482z"
      fill="#fcfcfc"
      fillRule="evenodd"
    />
  </svg>
)

export default XUPTiny
