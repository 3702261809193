import React from 'react'

const Login = (props) => (
  <svg width="820px" height="600px" viewBox="0 0 820 600" {...props}>
    <title>Login</title>
    <g
      id="Artboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Fill-1"
        fill="#597DB8"
        points="-169.661711 284.526283 -93.7409886 294.716207 -41.621597 320.37999 10.4977947 270.741207 80.3374335 190.477424 44.8962738 112.298337 -169.661711 112.298337"
      ></polygon>
      <path
        d="M-169.661711,284.526283 C-158.45173,260.507367 -139.231008,243.684078 -119.030152,243.599924 C-104.5677,243.540352 -92.1140875,252.07077 -88.0318631,254.866778 C-69.7041065,267.42153 -72.3663688,278.995485 -57.0342395,286.805941 C-43.7515399,293.572386 -24.9001141,293.472576 -14.113327,284.526283 C-5.07853612,277.032557 -7.71484791,268.317823 -0.975019011,265.203089 C11.4113878,259.480656 35.7623384,281.772861 49.0743156,300.896435 C72.0791635,333.942158 72.1490304,371.929135 69.9691825,393.821435 L44.3053992,470.384268 L-80.8788213,430.780371 L-169.661711,462.199192 L-169.661711,284.526283 Z"
        id="Fill-2"
        fill="#FD7369"
      ></path>
      <polygon
        id="Fill-4"
        fill="#FD7369"
        points="136.011255 498.69228 181.718859 543.509323 185.728555 605.02347 212.975989 629.303471 202.567814 652.477282 212.975989 679.543536 113.558688 679.543536 113.558688 489.904753"
      ></polygon>
      <path
        d="M203.321844,679.183023 C192.750646,671.874331 184.64076,663.799127 185.78192,656.382445 C186.72346,650.259519 193.309582,648.872597 194.551217,642.42782 C196.210057,633.819021 185.884392,628.877626 178.182395,617.240184 C163.978118,595.779239 177.627452,580.151837 163.037909,546.608083 C158.012148,535.052515 155.970703,535.937605 152.819373,526.24256 C146.541331,506.928698 149.688669,488.175251 153.484772,475.078894 L203.321844,395.722947 C448.426071,63.5194784 586.81879,-76.0548372 618.5,-23 C627.353881,-8.17287655 604.136853,15.6006651 598.5,71.4545639 C592.41746,131.72464 603.066236,224.787093 590.188911,254.480423 C583.243188,270.496298 530.022113,298.096585 503.329884,350.586957 C478.747655,398.928005 435.166838,508.460027 372.587433,679.183023 L203.321844,679.183023 Z"
        id="Fill-6"
        fill="#A0EBF1"
      ></path>
      <path
        d="M24.8554468,318.918729 C22.2996483,309.797983 31.0223669,298.472697 24.8554468,290.136997 C16.11077,278.315306 -9.34008555,290.404721 -13.8301996,281.713449 C-17.8192681,273.989889 -1.73590304,256.674277 7.60896388,246.613708 C29.9657129,222.545035 43.0082034,219.671184 43.7873859,207.30289 C44.5352947,195.435881 33.4870057,182.884224 22.3482224,177.819079 C2.43748099,168.765962 -11.1280133,186.651882 -28.5694582,177.819079 C-45.5551046,169.217746 -43.6360932,146.708705 -54.0282985,145.528349 C-71.0571958,143.592927 -86.9163213,202.815723 -98.2460741,200.282802 C-107.900352,198.124277 -100.188375,154.276791 -118.345124,145.528349 C-130.405485,139.716506 -144.31499,154.001398 -158.543888,145.528349 C-164.826587,141.78579 -168.051778,135.464294 -169.662044,131.488174 L-169.662044,-19.3346008 L386.813115,-19.3346008 C386.863241,-4.83915496 384.713337,17.170692 370.731968,34.6145886 C352.044895,57.928196 330.279686,50.5616008 317.845371,71.1170926 C305.513527,91.5052568 324.758203,102.331349 321.602215,157.849931 C319.964667,186.652579 313.825694,200.625824 321.602215,224.150682 C327.631397,242.385201 334.508298,243.631792 333.213432,252.229639 C329.270276,278.428949 265.163051,268.574054 237.64347,311.785695 C225.190523,331.34001 230.149078,346.184752 216.93558,353.939687 C199.975219,363.892887 174.074553,349.840162 153.484439,335.034463 C59.665808,342.259527 28.7966065,332.979123 24.8554468,318.918729 Z"
        id="Fill-9"
        fill="#6763AE"
      ></path>
      <path
        d="M-169.661711,399.011874 C-133.980342,402.810618 -122.698498,396.409031 -119.33557,389.394519 C-114.440894,379.185133 -125.80924,366.607627 -119.33557,355.962228 C-112.939753,345.44429 -94.9979278,346.468851 -92.5585741,346.608152 C-76.9290114,347.500376 -71.1693156,356.412868 -35.8545817,380.875553 C-17.3052471,393.724697 -18.1962167,391.750102 -9.07825095,399.011874 C16.1084411,419.071249 23.145038,434.332393 28.7244106,431.987257 C33.1779278,430.115048 32.8478897,418.650559 28.7244106,372.632404 C24.3540684,323.858175 22.7630989,314.850262 28.7244106,309.992829 C41.8001711,299.339072 66.8571103,328.501084 98.8874335,316.783763 C107.477072,313.641824 110.878593,309.641093 119.121559,309.992829 C134.053118,310.630132 145.744848,324.732986 153.484772,337.076467 C146.108156,399.10242 149.050551,445.907627 153.484772,478.151678 C157.994848,510.956416 154.530779,545.152077 163.037909,577.076432 C165.912433,587.862525 171.934962,607.269273 163.037909,623.24155 C157.471844,633.234322 149.678688,635.396277 147.467567,644.675131 C144.575076,656.811052 153.913954,669.869844 163.037909,679.564512 L-169.661711,679.564512 L-169.661711,399.011874 Z"
        id="Fill-11"
        fill="#EC6761"
      ></path>
      <path
        d="M463.870494,-2.42402617 C412.733897,-2.42402617 351.485894,-11.260965 324.485323,25.1898736 C313.997966,39.3492921 288.532257,65.3369724 309.599011,113.73311 C332.16427,165.571724 288.881141,260.751356 282.795399,269.08993 C252.542357,310.546913 219.062129,286.598822 182.395304,328.338734 C138.665266,378.117929 142.605095,461.903181 158.637224,512.632063 C161.202858,520.750335 177.710255,513.531119 193.463555,547.410811 C197.603196,556.313691 186.929441,574.991567 193.463555,589.294996 C199.927026,603.44306 214.451056,612.900608 219.886412,624.980786 C224.088188,634.319309 208.624754,650.294869 210.20156,659.04261 C212.07153,669.416748 230.774373,672.278533 233.885228,679.333326 L495.73846,679.333326 C527.233022,637.225008 517.686057,575.30904 467.097566,493.585424 C391.214829,371 586.252482,239.712304 577.395437,134.034153 C574.478054,99.2252905 547.259062,53.7392308 495.73846,-2.42402617 L463.870494,-2.42402617 Z"
        id="Fill-13"
        fill="#3DEBF1"
      ></path>
      <path
        d="M164.030418,388.818753 C161.789832,404.097314 155.036688,421.42408 155.983551,431.3277 C159.510832,468.228955 170.601302,476.306082 169.316416,481.567228 C166.650162,492.484296 165.100447,490.38207 158.637424,508.751789 C151.157671,530.011895 151.499686,537.754002 145.415941,558.324991 C138.447215,581.886804 134.093508,584.932033 130.299401,598.014102 C125.457956,614.707062 123.562234,640.721937 139.953013,679.299254 L-169.661911,679.299254 L-169.661911,423.866906 C-140.101549,430.833407 -102.839192,435.895072 -91.8101996,418.197033 C-82.6083935,403.429835 -97.212576,381.71706 -87.9468916,373.647831 C-77.7889068,364.801602 -48.1287357,380.347195 -27.6743631,398.757369 C2.72972433,426.121882 4.65672053,452.490383 21.7801046,453.026054 C35.7747814,453.464075 50.5147053,436.354018 53.462424,420.627078 C55.9197433,407.51153 48.8365684,402.516824 44.9619487,377.697442 C42.6822909,363.096943 40.2036787,347.216556 44.9619487,338.007633 C54.7459791,319.073647 141.739544,302.22705 153.484572,336.328084 C162.00886,361.136156 165.524142,378.633045 164.030418,388.818753 Z"
        id="Fill-16"
        fill="#E76161"
      ></path>
      <path
        d="M178.852785,344.864354 C197.100029,322.251509 229.592186,322.021523 249.727785,313.281944 C269.491898,304.703603 269.491898,304.703603 303.292059,271.777567 C337.09222,238.85153 362.850987,155.597342 355.964097,132.692241 C349.077207,109.78714 310.653834,94.7522965 341.380069,49.7522965 C372.106305,4.75229653 424.668356,11.7107676 477.721968,-23 C530.77558,-57.7107676 610.703232,-41.4220627 627.714829,-55 C561.048162,52.27616 527.714829,138.264513 527.714829,202.965059 C527.714829,344.864354 333.482253,375.5 334.5,498.000711 C334.843173,539.306548 388.589126,599.835484 495.737861,679.587519 L311.81885,679.587519 C323.204496,641.247647 316.871892,624.297733 309.593089,615.876656 C289.490048,592.617925 244.859724,614.38914 226.132063,589.64221 C215.576169,575.695292 230.50307,564.116397 221.680542,540.594401 C210.826549,511.657053 185.647842,518.212084 169.316283,486.236274 C156.866663,461.860371 155.350757,425.616637 155.350757,422.631654 C155.350757,419.64667 157.311348,405.453086 159.841075,400.714992 C162.370803,395.976899 163.697719,391.789305 163.365019,390.077152 C163.032319,388.364999 165.649717,379.470185 172.713893,370.716361 C164.641659,368.556919 166.687956,359.939583 178.852785,344.864354 Z"
        id="Fill-19"
        fill="#3DD7D5"
      ></path>
      <path
        d="M98.8871008,314.779636 C57.5145722,297.995894 36.8279753,289.604372 31.9499335,274.509534 C21.9769297,243.649038 75.3313023,215.015078 66.1794011,186.438995 C58.0562072,161.07685 13.6388118,176.164018 -23.5789639,141.001769 C-60.5884696,106.035466 -54.5359981,55.3376739 -75.3031084,53.3154523 C-97.2220247,51.1809626 -110.361663,107.033328 -137.676968,105.927382 C-151.635713,105.361857 -162.545599,90.1965898 -169.662044,77.2299657 L-169.662044,-19.3346008 L309.590519,-19.3346008 C309.67699,-3.8445339 279.762859,2.62923929 277.452241,26.5779468 C274.994116,52.055518 289.245778,75.2942585 286.263911,91.8751879 C271.988436,171.264817 278.522937,209.597616 244.934249,241.03828 C225.456683,259.268957 204.638337,266.838341 190.166568,297.112393 C181.574268,315.087153 179.340523,332.443393 178.852785,344.638783 C172.998603,342.424799 164.19404,339.104172 153.484439,335.095292 C110.872937,319.145542 107.947177,318.454501 98.8871008,314.779636 Z"
        id="Fill-22"
        fill="#6750AE"
      ></path>
      <polygon
        id="Path"
        fill="#6750AE"
        points="163.037643 321.863498 206.953992 385.741825 119.121293 385.741825"
      ></polygon>
      <path
        d="M153.486502,335.756369 L153.929196,337.006302 C159.188362,352.017771 162.680156,367.884398 164.147185,384.330727 L164.267966,385.741825 L119.121293,385.741825 L153.486502,335.756369 Z"
        id="Path"
        fill="#E76161"
      ></path>
      <path
        d="M178.850856,344.864354 L206.953992,385.741825 L164.409696,385.741825 C167.618074,371.922902 172.282657,358.666689 178.230943,346.155906 L178.850856,344.864354 Z"
        id="Path"
        fill="#3DD7D5"
      ></path>
      <path
        d="M211.708674,243.830798 L115.021364,243.830798 L163.365019,314.148842 L211.708674,243.830798 Z M202.854,248.488 L163.365,305.926 L123.875,248.488 L202.854,248.488 Z"
        id="Stroke-37"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
      <path
        d="M211.381563,388.070589 L163.037909,317.752546 L114.694254,388.070589 L211.381563,388.070589 Z M163.038,325.974 L202.526,383.412 L123.549,383.412 L163.038,325.974 Z"
        id="Stroke-37"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

export default Login
