import React from 'react'
import window from 'window-or-global'

export default function useWindowHeight() {
  const [height, setHeight] = React.useState(window.innerHeight)

  React.useLayoutEffect(() => {
    const handleResize = () => setHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return height
}
