import React from "react";
import cx from "classnames";
import useAuthState from "Hooks/useAuthState";
import useScrollPosition from "Hooks/useScrollPosition";
import useWindowWidth from "Hooks/useWindowWidth";
import FeedbackButton from "Components/FeedbackButton";
// import Tooltip from 'Components/Tooltip'
import XupFooter from "Components/XupFooter";
import XupNav from "Components/XupNav";
// import UserInfo from 'Components/UserInfo'
import FacebookIcon from "Svg/FacebookIcon";
import InstagramIcon from "Svg/InstagramIcon";
import BrandAsideBG from "Svg/BrandAsideBG";
//import InfoIcon from 'Svg/InfoIcon'
import SocialIcon from "Components/SocialIcon";
// import XupWordmark from 'Svg/XupBetaWordmark';
import Announcement from "Components/Announcement/index";
import styles from "./brandAside.module.scss";
import Announcment from "Components/Announcement/index";
import { lastDayOfQuarterWithOptions } from "date-fns/fp";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export function FeedbackSocial() {
  let width = useWindowWidth();

  return (
    <>
      <div className={styles.actions}>
        <FeedbackButton />
        <section className={styles.socialIcons}>
          <SocialIcon link="https://www.instagram.com/xupapp/">
            <InstagramIcon />
          </SocialIcon>
          <SocialIcon link="https://www.facebook.com/whatsxup/">
            <FacebookIcon />
          </SocialIcon>
        </section>
      </div>
    </>
  );
}

function BrandAside({ experiencesCount }) {
  let width = useWindowWidth();

  let position = useScrollPosition();
  let collapsed = position.y > 0;

  const { authState } = useAuthState();

  return (
    <aside className={cx(styles.aside, { [styles.collapsed]: collapsed })}>
      {width > 1024 && (
        <>
          <BrandAsideBG />
        </> // Animated BG using svg image on mobile
      )}
      <div className={styles.container}>
        <Announcement />
        <div className={styles.title}>
          <section className={styles.titleText}>
            <section className={styles.countContainer}>
              <h1 className={cx(styles.h1, "hero")}>{`${experiencesCount}`}</h1>
            </section>
            <section className={styles.upcomingContainer}>
              <span className={styles.span}>upcoming</span>
              <h1 className={cx(styles.h1, "hero")}>{`${experiencesCount !== 1 ? "xperiences" : "xperience"}`}</h1>
            </section>
            {/* <p className={styles.p}>
              this week{' '}
              {/* <span className={styles.tooltipIcon} data-for="info" data-tip="">
                <InfoIcon />
              </span> */}
            {/* <Tooltip
                effect="solid"
                place="right"
                id="info"
                label="Notifications"
              >
                Every Sunday night, we’ll add the Xperiences for the following
                week.
              </Tooltip> 
            </p> */}
          </section>
        </div>
        <FeedbackSocial />
      </div>
    </aside>
  );
}

export default BrandAside;
