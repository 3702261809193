import React, { useRef, useState } from "react";
import styles from "./support.module.scss";
import useWindowWidth from "Hooks/useWindowWidth";
import Modal from "react-modal";
import Dissmiss from "Svg/Dismiss";
import XUPTiny from "Svg/XUPTiny";
import { Formik, Field, setNestedObjectValues } from "formik";
import { document, console } from "window-or-global";
import CheckIcon from "Svg/CheckIcon";
import { useTransition, animated } from "react-spring";
import cx from "classnames";
import emailjs from "emailjs-com";

export default function SupportModal({ link = "", variant }) {
  const AnimatedCheckIcon = animated(CheckIcon);
  const [submitted, setSubmitted] = useState(false);

  const transitions = useTransition(submitted, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  if (typeof window !== "undefined" && !link) {
    link = window.location.pathname;
  }

  const linkSplit = link.split("/")[1];

  function checkPage() {
    if (linkSplit === "xperiences") {
      return true;
    } else {
      return false;
    }
  }

  let width = useWindowWidth();
  function supportOpen() {
    setIsOpen(true);
  }
  let form = useRef(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  // const { authState, setAuthState, firebaseInstance } = useAuthState();
  function afterSupportOpen() {
    // references are now sync'd and can be accessed.
  }

  function closeModal(props) {
    setIsOpen(false);
    props = initialState;
  }

  const initialState = { feedback: null, contactCheck: false, radioGroup: null };

  const submitRef = React.useRef();

  function validateFeedback(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    if (value) {
      submitRef.current.classList.add(`${styles.activeButton}`);
    }
    return error;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 9999999999999,
    },
  };

  function handleActive() {
    document.querySelector("form").classList.add(`${styles.active}`);
  }
  function sendSupport(values) {
    emailjs.init("user_LR2tAW4YAWIQFlP2HXLBr");
    let templateId = "template_btaln0a";
    let userID = "user_LR2tAW4YAWIQFlP2HXLBr";
    let variables = {
      message_html: values,
      from_name: "XUP",
      reply_to: "lists@xupapp.com",
    };
    window.emailjs
      .send("gmail", templateId, variables, userID)
      .then((res) => {
        setSubmitted(true);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Oh well, you failed. Here some thoughts on the error that occured:", err));
  }

  const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, onSelect, ...props }) => (
    <div className={styles.radioWrapper}>
      <input
        name={name}
        id={id}
        type="radio"
        value={label}
        checked={label === value}
        onChange={onChange}
        onClick={onSelect}
        onBlur={onBlur}
        className={styles.radio}
        {...props}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );

  return (
    <>
      <button
        onClick={() => {
          document.getElementById("support").click();
        }}
        className={cx(styles.supportButton)}
      >
        {checkPage() ? "Report a Problem" : "Ermergerd, Need Help?"}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterSupportOpen}
        onRequestClose={closeModal}
        styles={customStyles}
        className={cx(styles.support, styles.animate__bounceInUp)}
        contentLabel="Example Modal"
      >
        <div className={cx(styles.supportModalWrapper, styles.animate__bounceInUp)}>
          <section className={styles.supportModal}>
            <header className={styles.header}>
              <div>
                <XUPTiny className={styles.tiny} />
                <p className={styles.title}>Support</p>
              </div>
              <button onClick={closeModal} className={styles.supportModalDissmiss}>
                <Dissmiss />
              </button>
            </header>
            <div className={styles.titleWrapper}>
              <h3>How can we help you?</h3>
            </div>
            <Formik
              className={styles.supportForm}
              onClick={handleActive}
              useRef={form}
              initialValues={{
                feedback: "",
                contactCheck: false,
                radioGroup: null,
              }}
              onSubmit={(values, actions) => {
                sendSupport(values);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={props.label}
                    onSelect={props.handleChange}
                    label="Something is wrong / incorrect"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={props.label}
                    onSelect={props.handleChange}
                    label="I need help"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={props.label}
                    onSelect={props.handleChange}
                    label="Just want to say hey"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    component={RadioButton}
                    name="radioGroup"
                    id={props.label}
                    onSelect={props.handleChange}
                    label="Other"
                    className={cx(styles.radioField)}
                  />
                  <Field
                    as="textarea"
                    name="feedback"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.feedback}
                    onClick={handleActive}
                    onTouchStart={handleActive}
                    className={styles.input}
                    validate={validateFeedback}
                    placeHolder="Tell us your deal"
                    {...(submitted ? { disabled: "true" } : {})}
                  ></Field>
                  <Field as="checkbox" className={styles.checkBox}>
                    <label>
                      <input
                        name="contactCheck"
                        type="checkbox"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.contactCheck}
                        className={styles.inputCheck}
                      />
                      I’d like to be contacted about this.
                    </label>
                    <p className={styles.checkSub}>We’ll use the email associated with your account.</p>
                  </Field>

                  <button
                    className={cx(styles.submitButton, {
                      [styles.submitted]: submitted,
                    })}
                    ref={submitRef}
                    {...(buttonActive ? { disabled: "true" } : {})}
                    type="submit"
                  >
                    {transitions.map(({ item, key, props }) =>
                      item ? <AnimatedCheckIcon className={styles.check} key={key} style={props} /> : null
                    )}
                    {submitted ? "Submitted" : "Submit"}
                  </button>
                </form>
              )}
            </Formik>

            <section className={styles.bottomCaption}>
              <div className={styles.line}></div>
              <div className={styles.circle}></div>
              <p>XUP will do its best to help you as soon as we can. ✌️🥰🙅</p>
            </section>
          </section>
        </div>
      </Modal>
    </>
  );
}
