import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
  apiKey: "AIzaSyB8HEsSeX7IbUaq4yDZ7hm4pTXV9NoOk6o",
  authDomain: "regal-oasis-253117.firebaseapp.com",
  databaseURL: "https://regal-oasis-253117.firebaseio.com",
  projectId: "regal-oasis-253117",
  storageBucket: "regal-oasis-253117.appspot.com",
  messagingSenderId: "408643212634",
};
// Get a reference to the database service

let instance = null;
export default function getFirebaseInstance() {
  if (typeof window !== "undefined") {
    if (!instance) {
      instance = firebase.initializeApp(config);
      //var database = firebase.database()
    }
  }

  return instance;
}
